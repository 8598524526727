import { createAction } from 'redux-actions';
import HistoryConstants from '../constants/HistoryConstants';
import Store from '../stores/Main';
import { APIGet, APIFetchErrorText } from "../services/API";

const isLoadingHistoryAction = createAction(HistoryConstants.IS_LOADING_HISTORY);
const isErrorHistoryAction = createAction(HistoryConstants.IS_ERROR_HISTORY);
const getHistoryCustomerNumberAction = createAction(HistoryConstants.GET_HISTORY_CNUMBER);


export const getHistoryCustomerNumber = async coruscantUserId => {
	Store.dispatch(isLoadingHistoryAction());
	try {
		const { data } = await APIGet(`/history/cnumber?coruscant_userid=${coruscantUserId}`);
		Store.dispatch(getHistoryCustomerNumberAction(data));
	} catch (err) {
		Store.dispatch(isErrorHistoryAction());
		err.message = APIFetchErrorText(err);
		throw err;
	}
};