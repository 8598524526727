import React from 'react';
import {connect} from 'react-redux';
import {getAIDemergency} from '../../actions/UIActions';
import toast from 'utils/toast';

class AIDStatus extends React.Component {
	componentDidMount(){
		const interval = 180000 // 3 minutes
		this.infiniteStatusCheck(interval);
	}

	// will call itself with pointed timeout
	async infiniteStatusCheck (ms){
		try {
			await getAIDemergency();
		} catch (err) {
			toast.error(err.message)
		}

		await this.delay(ms);
		this.infiniteStatusCheck(ms)
	}

	delay(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	render() {
		const { emergency } = this.props;
		const className = emergency ? 'emergency ON' : 'emergency OFF';
		return <>
			{
				<div className={className}><span>aID</span></div>
			}
		</>
	}

}

const mapStateToProps = state => ({
	emergency: state.UI.getIn(['AIDEmergency'], false),
});

const mapDispatchToProps = () => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(AIDStatus);