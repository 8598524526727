import axios from 'axios';

export const API_URL = process.env.API_URL;

const API = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

export const APIGet = (url, params, options = {}) => {
    return API({
        method: 'get',
        url,
        params: params,
        responseType: options.responseType || 'json',
        headers: Object.assign(options.headers || {}, axios.defaults.headers),
    });
};

export const APIPost = (url, data, options = {}) => {
    return API({
        method: 'post',
        url,
        data,
        responseType: options.responseType || 'json',
        headers: Object.assign(options.headers || {}, axios.defaults.headers),
    });
};

export const APIPut = (url, data, options = {}) => {
    return API({
        method: 'put',
        url,
        data,
        responseType: options.responseType || 'json',
        headers: Object.assign(options.headers || {}, axios.defaults.headers),
    });
};

export const APIPatch = (url, data, options = {}) => {
    return API({
        method: 'patch',
        url,
        data,
        responseType: options.responseType || 'json',
        headers: Object.assign(options.headers || {}, axios.defaults.headers),
    });
};

export const APIDelete = (url, params, options = {}) => {
    return API({
        method: 'delete',
        url,
        data: params,
        responseType: options.responseType || 'json',
        headers: Object.assign(options.headers || {}, axios.defaults.headers),
    });
};

export const APIFetchErrorText = err => {
	try {
		if (err.response.data.error){
			return err.response.data.error;
		}
		return "Couldn't parse error response";
	} catch(err) {
		return "Something went wrong. Please, try later.";
	}
}
