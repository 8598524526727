import React from "react";
import { BackButton } from "../../../../styles/shared/buttons";
import { pageContainer } from "../../../../styles/components/layout/main";
import { withRouter } from "react-router";
import { Container } from 'react-bootstrap'
import connect from "react-redux/es/connect/connect";
import { getHistoryCustomerNumber } from '../../../../actions/HistoryActions';
import HistoryCustomerNumber from './HistoryCustomerNumber';
import toast from "../../../../utils/toast";


class  HistoryPage extends React.Component {
	componentDidMount() {
		getHistoryCustomerNumber(this.props.match.params.id).catch(err => toast.error(err.message))
	}

	render() {
		const { loading, historyCNumberRecords } = this.props;
		const coruscantUserId = this.props.match.params.id;
		return (
			<Container css={pageContainer}>
				
				{loading && <div className="loader"/>}
				
				<BackButton className="fas fa-arrow-left" to={"/"}/>
				
				{ historyCNumberRecords.length ?
					<HistoryCustomerNumber coruscantUserId={coruscantUserId} historyCNumberRecords={historyCNumberRecords}/>
					:
					<div> No data available </div>
				}

				</Container>

		)
	}
}

const mapStateToProps = (state, props) => ({
	historyCNumberRecords: state.History.getIn(['historyCNumberRecords']),
	loading: state.History.getIn(['loading'], false),
});

const mapDispatchToProps = () => ({ });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryPage));