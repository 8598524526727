import React from 'react';
import makeCancelable from '../../services/CancelablePromises';


export default class LoaderComponent extends React.Component {
	constructor(props) {
		super(props);
		this.setLoading = () => this.setState({loading: false});
		
		this.state = {
			loading: true
		};
	}
	
	componentDidMount() {
		this.handlePromise();
	}
	
	componentWillUnmount() {
		this.cancelable && this.cancelable.cancel();
	}
	
	handlePromise(props) {
		props = props || this.props;
		const {promise} = props;
		this.cancelable = makeCancelable(promise);
		this.setState({loading: true});
		
		this.cancelable.promise
		.then(this.setLoading)
		.catch(err => !err.hasOwnProperty('isCanceled') && this.setLoading());
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.promise) {
			this.handlePromise(nextProps);
		}
	}
	
	render() {
		const {loading} = this.state;
		const {showLoader = true} = this.props;
		
		return <React.Fragment>
			{
				loading ?
					showLoader ? <div className="loader"/> : <div/>
					: this.props.children
			}
		</React.Fragment>;
	}
}