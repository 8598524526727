import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import UIConstants from '../constants/UIConstants';

	export const InitialState = Immutable.from({
	isMobile: true,
	modalData: {},
	});

	const UIReducer = handleActions({
	[UIConstants.DEVICE_CHANGED]: (state, action) =>
		state.set('isMobile', action.payload),

	[UIConstants.OPEN_MODAL]: (state, action) => {
		const {payload: {name, data}} = action;
		return state.merge({
			[name]: true,
			modalData: data,
		});
	},
	[UIConstants.CLOSE_MODAL]: (state, action) => {
		const {payload: name} = action;
		return state.merge({
			[name]: false,
			modalData: {},
		});
	},
	[UIConstants.UPDATE_AIDEMERGENCY]: (state, action) => {
		return state.set('AIDEmergency', action.payload.emergency)
	},
}, InitialState);

export default UIReducer;
