import { css } from '@emotion/core';
import theme from 'Theme';

export const pageContainer = css`
	max-width: 90vw;
	height: 100%;
	margin: 0 auto;
	padding-top: 3rem;
	position: relative;
`;

export const subtitle = css`
	margin-bottom: 1rem;
	font-family: "Roboto";
	font-size: 1rem;
	font-weight: 400;
	color: ${theme.colors.greyMediumDark};
`;

export const centerText = css`
	text-align: center;
`

export const formContainer = css`
	label {
		text-align: right;
	}
`;

