import React from 'react';
import { connect } from 'react-redux';
import { getUsers, addUser } from '../../../actions/UsersActions';
import UsersRow from '../../../components/pages/users/Row';
import ButtonComponent from '../../../components/common/Button';
import {ToggleModal} from '../../../actions/UIActions';
import ModalNames from '../../../constants/Modals';
import LoaderComponent from '../../../components/common/Loader';
import toast from '../../../utils/toast';


class UsersPage extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {refresh: getUsers()};
		
		this.openUsersModal = this.openUsersModal.bind(this);
		this.addNewUser = this.addNewUser.bind(this);
		this.refreshUsers = this.refreshUsers.bind(this);
	}
	
	refreshUsers() {
		this.setState({refresh: getUsers()})
	}
	
	async addNewUser(user) {
		try {
			await addUser(user)
			toast.success('User was created')
		} catch (err) {
			toast.error(err.message)
		}
	}
	
	openUsersModal() {
		ToggleModal(ModalNames.USER_MODAL, true, {
			onSuccess: this.addNewUser,
		});
	}
	
	render() {
		const { users } = this.props;
		const { add, refresh } = this.state;
		
		return (
			<div id="users-container">
				<div className="top-btns-container">
					<ButtonComponent
						className="refresh"
						onClick={this.refreshUsers}
						icon="&#10227;"
						handler={refresh}
					/>
					<ButtonComponent
						className="create"
						onClick={this.openUsersModal}
						title="Create"
						handler={add}
					/>
				</div>
				
				<LoaderComponent promise={refresh} showLoader={false}>
					{users.map((u, i) => <UsersRow key={i} {...u} />)}
				</LoaderComponent>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	users: state.Users.getIn(['users'], [])
});

export default connect(mapStateToProps, () => ({}))(UsersPage);
