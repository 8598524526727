export const emailValidator = {
    emailRegexp :  /^([a-z0-9_.-]+)@([a-z0-9_.-]+)\.([a-z]{2,4})$/i,
    validate: function (email) {
        return this.emailRegexp.test(email)
    }
};

export const numberValidator = {
    numberRegexp :  /^([0-9]+)$/,
    validate: function (value) {
        return this.numberRegexp.test(value)
    }
};

export const phoneValidator = {
    validate: function (phone) {
        return numberValidator.validate(phone)
    }
};