import { css } from '@emotion/core';
import theme from 'Theme';

export const table = css`
	background-color: white;
`;

export const container = css`
	background-color: white;
	padding: 20px;
`;

export const row = css`
	background-color: white;
	padding: 5px;
`;

export const searchContainer = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1rem;
`;

export const box = css`
	padding: 1rem 2rem;
`;

export const searchBlock = css`
	${box};
	position: relative;
	width: 48%;
	background-color: ${theme.colors.white};
`;

export const searchTopContainer = css`
	display: flex;
	justify-content: space-between;
`;

export const searchTitle = css`
	margin-bottom: 1rem;
	font-family: "Roboto";
	font-size: 1.2rem;
	font-weight: bold;
	color: ${theme.colors.greyMediumDark};
`;

export const searchInputContainer = css`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 1.4rem;
    margin-bottom: 0.6rem;
`;

export const searchInput = css`
	padding: 0.4rem 0.6rem;
 	width: 100%;
 	font-size: 1rem;
`;

export const error = css`
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 0.8rem;
	color: ${theme.colors.redLight};
`;