import React from 'react';
import { Route, Redirect } from 'react-router';


export default class ClosedRoute extends React.Component {
	render() {
		const { component, condition, ...options } = this.props;
		return <Route
			{...options}
			render={
				props =>
					condition ? React.createElement(component, props) : <Redirect to={'/'} />
			}
		/>
	}
}