import React from 'react';
import toast from 'utils/toast';
import {
	searchBlock,
	searchTopContainer,
	searchTitle,
	searchInputContainer,
	searchInput,
} from '../../../styles/components/pages/home/home';
import { buttonPrimary, buttonContainer } from '../../../styles/shared/buttons';
import { getCoruscantAidUser } from "../../../actions/CustomerActions";


export default class AIDCoruscantSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: null,
		};
	}
	
	onChange = ({ target: { value }}) => this.setState({ uuid: value })
	
	submit = () => {
		if (!this.state.uuid) {
			return toast.error("uuid should be provided");
		}
		
		return getCoruscantAidUser(this.state.uuid)
			.catch(err => toast.error(err.message))
	};
	
	render () {
		return (
			<div css={searchBlock}>
				<div css={searchTopContainer}>
					<p css={searchTitle}>
						Search for AID User in Coruscant
					</p>
				</div>
				<div css={searchInputContainer}>
					<input
						css={searchInput}
						placeholder="aid uuid"
						onChange={this.onChange}
					/>
				</div>
				<div css={buttonContainer}>
					<button
						css={buttonPrimary}
						onClick={this.submit}>
						Search
					</button>
				</div>
			</div>
		);
	}
}