import React from 'react';
import ButtonComponent from '../../../components/common/Button';
import {deleteUser, editUser} from '../../../actions/UsersActions';
import ModalNames from '../../../constants/Modals';
import {ToggleModal} from '../../../actions/UIActions';
import toast from '../../../utils/toast';


export default class UsersRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		
		this.editUser = this.editUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
	}
	
	async deleteUser() {
		const {id} = this.props;
		try {
			await deleteUser(id);
			toast.success('User was deleted')
		} catch (err) {
			toast.error(err.message)
		}
	}
	
	editUser() {
		ToggleModal(ModalNames.USER_MODAL, true, {
			user: {
				picture: this.props.picture,
				email: this.props.email,
				name: this.props.name,
				isNew: !this.props.sub,
			},
			
			onSuccess: async (data) => {
				try {
					await editUser(this.props.id, data);
					toast.success('User was updated')
				} catch (err) {
					toast.error(err.message)
				}
			},
		});
		
	}
	
	render() {
		const {
			id,
			email,
			name,
			picture,
			sub
		} = this.props;
		
		return (
			<div className="users-row">
				<span className="user-info id" title={id}>{id}</span>
				<img className="user-portrait" src={picture ? picture : "./assets/hacker.svg"} />
				<span className="user-info email">{email}</span>
				<span className="user-info name">{name || 'No name'}</span>
				
				{sub && <span className="user-info">(Authorized)</span>}
				
				<div className="btns-container">
					<ButtonComponent className="edit" onClick={this.editUser} icon="&#9998;"/>
					<ButtonComponent className="delete" onClick={this.deleteUser} icon="&#10010;"/>
				</div>
			</div>
		)
	}
}
