import React from 'react';
import {
	Container,
	Row,
	Form,
	Col,
} from 'react-bootstrap'
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { pageContainer, formContainer } from "../../../styles/components/layout/main";
import { editPageContainer } from "../../../styles/components/pages/edit/editCustomer";
import {BackButton} from "../../../styles/shared/buttons";


const ViewAidUserPage = ({ coruscantAIDUser: {
	name,
	email,
	user_id,
	aid_uuid,
	phone_number,
	last_update,
}}) => (
	<Container css={pageContainer}>
		<BackButton className="fas fa-arrow-left" to={"/"}/>
		
		<div css={editPageContainer}>
			<Form css={formContainer}>
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>Name</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={name}/>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>Email</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={email}/>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>User ID</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={user_id}/>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>Phone Number</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={phone_number}/>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>Last Update</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={last_update}/>
					</Col>
				</Form.Group>
				
				<Form.Group as={Row}>
					<Form.Label column sm="3"><b>AID UUID</b></Form.Label>
					<Col sm="9">
						<Form.Control plaintext readOnly defaultValue={aid_uuid}/>
					</Col>
				</Form.Group>
			</Form>
		</div>
	</Container>
)

const mapStateToProps = state => ({
	coruscantAIDUser: state.Customer.getIn(['coruscantAIDUser'], null),
});

const mapDispatchToProps = () => ({ });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAidUserPage));