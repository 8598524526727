import { css } from '@emotion/core';
import styled from '@emotion/styled'
import theme from 'Theme';
import { Link } from "react-router-dom";
import {Container} from "react-router-dom";
import React from "react";

<Link className="fas fa-arrow-left" to={'/'} />


export const buttonContainer = css`
	display: flex;
	justify-content: flex-end;
`;

export const buttonPrimary = css`
	text-transform: uppercase;
	font-size: 0.8rem;
	height: 2rem;
	width: 6.8rem;
	border-radius: 1rem;
	color: ${theme.colors.white};
	background-color: ${theme.colors.redRossoCorsa};
	border: none;
	
	&:focus {
	  background-color: ${theme.colors.redFocus};
	  outline: transparent;
	}
	
	&:hover {
	  background-color: ${theme.colors.redHover};
	}
`;

export const buttonSuccess = css`
	${buttonPrimary};
	background-color: ${theme.colors.greenSalad};
	
	&:focus {
	  background-color: ${theme.colors.greenLight};
	}
	
	&:hover {
	  background-color: ${theme.colors.greenLighter};
	}
`;

export const buttonWarning = css`
	${buttonPrimary};
	background-color: ${theme.colors.warning};
	
	&:focus {
	  background-color: ${theme.colors.warningFocus};
	}
	
	&:hover {
	  background-color: ${theme.colors.warningHover};
	}
`;

export const buttonAction = css`
	${buttonPrimary};
	background-color: ${theme.colors.action};
	
	&:focus {
	  background-color: ${theme.colors.actionFocus};
	}
	
	&:hover {
	  background-color: ${theme.colors.actionHover};
	}
`;

export const BackButton = styled(Link)`
	color: ${theme.colors.greyMediumDark};
	position: absolute;
	top: 1rem;
`;