import React from "react";
import { table } from "../../../../styles/components/pages/home/home";
import { subtitle, centerText } from "../../../../styles/components/layout/main";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";


const Customers = (props) => (
	<>
		<div css={subtitle}>Coruscant Users</div>
		<Table css={table} bordered hover>
			<thead>
			<tr>
				<th>User ID</th>
				<th>Name</th>
				<th>Customer Number</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			{props.items.map((user, key) => {
				return (
					<tr key={key}>
						<td>
							<OverlayTrigger
								placement="top"
								overlay={
									<Tooltip id="tooltip-top">
										 <strong>Click to check history</strong>.
									</Tooltip>
								}
							>
								<Link to={`/history/${user.id}`}>{user.id}</Link>
							</OverlayTrigger>
						</td>
						<td>{user.first_name}</td>
						<td>{user.customer_number}</td>
						<td css={centerText}>
							<Link to={`/edit/${user.id}`}>View</Link>
						</td>
					</tr>
				)
			})
			}
			</tbody>
		</Table>
	</>
)


export default withRouter(Customers);