import { createAction } from 'redux-actions';
import CustomersConstants from '../constants/CustomerConstants';
import Store from '../stores/Main';
import { APIGet, APIPatch, APIFetchErrorText } from "../services/API";

const isLoadingAction = createAction(CustomersConstants.IS_LOADING);
const isErrorAction = createAction(CustomersConstants.IS_ERROR);
const searchCustomerAction = createAction(CustomersConstants.GET_CUSTOMER_RECORDS);
const updateCustomerAction = createAction(CustomersConstants.UPDATE_CUSTOMER);
const getCoruscantAidUserAction = createAction(CustomersConstants.GET_CORUSCANT_AID_USER);

export const searchCustomersByParams = async searchParams => {
	Store.dispatch(isLoadingAction());
	
	try {
		const { data: customerRecords } = await APIGet('/coruscant/user/search', searchParams);
        Store.dispatch(searchCustomerAction(customerRecords));
    } catch (err) {
		Store.dispatch(isErrorAction());
		err.message = APIFetchErrorText(err);
		throw err;
    }
};

export const getCoruscantAidUser = async uuid => {
	Store.dispatch(isLoadingAction());
	
	try {
		const { data } = await APIGet(`/coruscant/user/search/aid?id=${uuid}`);
		Store.dispatch(getCoruscantAidUserAction(data));
	} catch (err) {
		Store.dispatch(isErrorAction());
		err.message = APIFetchErrorText(err);
		throw err;
	}
};

export const updateCustomer = async customerData => {
	Store.dispatch(isLoadingAction());
	
	try {
        const { data } = await APIPatch(`/coruscant/user/cnumber`, customerData);
		Store.dispatch(updateCustomerAction(data));
    } catch (err) {
		Store.dispatch(isErrorAction());
		err.message = APIFetchErrorText(err);
		throw err;
    }
};