import {handleActions} from 'redux-actions';
import mergers from 'seamless-immutable-mergers';
import Immutable from 'seamless-immutable'    ;
import UsersConstants from '../constants/UsersConstants';

const mergeConfig = {
	merger: mergers.updatingByIdArrayMerger,
	mergerObjectIdentifier: 'id',
	deep: true,
};

export const InitialState = Immutable.from({
	users: [],
});

const UsersReducer = handleActions({
	[UsersConstants.GET_USERS]: (state, action) =>
		state.set('users', action.payload),
	
	[UsersConstants.ADD_USER]: (state, action) => {
		const user = action.payload;
		const users = state.getIn(['users']);
		return state.set('users', [user, ...users]);
	},
	[UsersConstants.DELETE_USER]: (state, action) => {
		let users = state.getIn(['users']);
		const userId = action.payload;
		const newUsers = users.flatMap((value) => {
			if (value.id === userId) {
				return [];
			} else {
				return value;
			}
		});
		return state.set('users', newUsers);
	},
	[UsersConstants.UPDATE_USER]: (state, action) => {
		return state.merge({users: [action.payload]}, mergeConfig);
	}
}, InitialState);

export default UsersReducer;
