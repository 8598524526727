import {createAction} from 'redux-actions';
import Store from '../stores/Main';
import PersonalDataConstants from '../constants/PersonalDataConstants';
import {APIDelete, APIGet, APIPost, APIPut, APIFetchErrorText} from '../services/API';
import UsersConstants from '../constants/UsersConstants';


const getUserAction = createAction(PersonalDataConstants.GET_USER);
const getUsersAction = createAction(UsersConstants.GET_USERS);
const addUsersAction = createAction(UsersConstants.ADD_USER);
const deleteUserAction = createAction(UsersConstants.DELETE_USER);
const editUserAction = createAction(UsersConstants.UPDATE_USER);

export const getUser = async () => {
	try {
		const {data: user} = await APIGet('/user');
		Store.dispatch(getUserAction(user));
	} catch (err) {
		err.message = APIFetchErrorText(err)
		throw err;
	}
};

export const getUsers = async () => {
	try {
		const {data} = await APIGet('/users');
		Store.dispatch(getUsersAction(data));
	} catch (err) {
		err.message = APIFetchErrorText(err);
		throw err;
	}
};

export const addUser = async userData => {
	try {
		const {data} = await APIPost('/users', userData);
		Store.dispatch(addUsersAction(data));
	} catch (err) {
		err.message = APIFetchErrorText(err);
		throw err;
	}
};

export const deleteUser = async id => {
	try {
		await APIDelete(`/users/${id}`);
		Store.dispatch(deleteUserAction(id));
	} catch (err) {
		err.message = APIFetchErrorText(err);
		throw err;
	}
};

export const editUser = async (id, userData) => {
	try {
		const {data} = await APIPut(`/users/${id}`, userData);
		Store.dispatch(editUserAction(data));
	} catch (err) {
		err.message = APIFetchErrorText(err);
		throw err;
	}
};
