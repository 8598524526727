import React from 'react';
import {emailValidator, phoneValidator} from '../../../utils/validation';
import {
	searchBlock, searchInput,
	searchInputContainer,
	searchTitle,
	searchTopContainer,
	error
} from "../../../styles/components/pages/home/home";
import { buttonPrimary, buttonContainer } from '../../../styles/shared/buttons';
import {
	searchCustomersByParams,
} from '../../../actions/CustomerActions';
import toast from "../../../utils/toast";


export default class CustomerSearch extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			errorEmail: false,
			errorPhone: false,
			email: "",
			phone: "",
		};
	}
	
	onPhoneChange = ({ target: { value } }) => {
		if (phoneValidator.validate(value) || !value) {
			this.setState({ errorPhone: false, 'phone': value });
		} else {
			this.setState({ errorPhone: true })
		}
	};
	
	onEmailChange = ({ target: { value } }) => {
		if (emailValidator.validate(value) || !value) {
			this.setState({ errorEmail: false, 'email': value });
		} else {
			this.setState({ errorEmail: true })
		}
	};
	
	onKeyUp = e => e.keyCode === 13 && this.submit();
	
	submit = () => {
		const { email, phone } = this.state;
		
		if (email || phone) {
			return searchCustomersByParams({ email, phone })
				.catch(err => toast.error(err.message))
		}
		
		return toast.error('Fill either phone number or email. Both will work as well.')
	};
	
	render() {
		const { errorEmail, errorPhone } = this.state;
		
		return (
			<div css={searchBlock}>
				<div css={searchTopContainer}>
					<p css={searchTitle}>
						Search for User in Coruscant
					</p>
				</div>
				
				<div css={searchInputContainer}>
					<input
						css={searchInput}
						placeholder="email"
						onChange={this.onEmailChange}
					/>
					{errorEmail && <div css={error}>Wrong email format</div>}
				</div>
				
				<div css={searchInputContainer}>
					<input
						css={searchInput}
						placeholder="mobile phone"
						onChange={this.onPhoneChange}
					/>
					{errorPhone && <div css={error}>Wrong phone format, only digits are allowed</div>}
				</div>
				
				<div css={buttonContainer}>
					<button
						css={buttonPrimary}
						onClick={() => this.submit()}>
						Search
					</button>
				</div>
			</div>
		);
	}
}