import React from 'react';
import makeCancelable from '../../services/CancelablePromises';


const ButtonComponentStates = {
	Loading: 'loading',
	Error: 'error',
	Success: 'success',
	Default: 'default',
};

export default class ButtonComponent extends React.Component {
	constructor(props) {
		super(props);
		this.setHandler = state => this.setState({state});
		
		this.state = {
			state: ButtonComponentStates.Default,
		};
		
		this.handlePromise = this.handlePromise.bind(this);
	}
	
	handlePromise(props) {
		props = props || this.props;
		const {handler} = props;
		if (handler) {
			this.cancelable = makeCancelable(handler);
			this.setHandler(ButtonComponentStates.Loading);
			
			this.cancelable.promise
			.then(() => this.setHandler(ButtonComponentStates.Success))
			.catch(err => !err.hasOwnProperty('isCanceled') &&
				this.setHandler(ButtonComponentStates.Error));
		}
	}
	
	componentDidMount() {
		this.handlePromise();
	}
	
	componentWillUnmount() {
		this.cancelable && this.cancelable.cancel();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.handler && nextProps.handler !== this.props.handler) {
			this.handlePromise(nextProps);
		}
	}
	
	render() {
		const {state} = this.state;
		let {className, children, onClick, title = children, icon} = this.props;
		className = `clever-btn ${state} ${className}`;
		
		return <button className={className} onClick={onClick}>
			<em>{icon}</em><span>{title}</span>
		</button>;
	}
}