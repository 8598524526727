import React from 'react';
import { connect } from 'react-redux';
import CustomerList from "./Customers";
import AidUser from "./AidUser";


class Results extends React.Component {
	render() {
		const { customerRecords, coruscantAIDUser } = this.props;
		return (
			<>
				{!!customerRecords.length && <CustomerList items={customerRecords}/>}
				{coruscantAIDUser && <AidUser aidUser={coruscantAIDUser} />}
			</>
		
		)
	}
}

const mapStateToProps = state => ({
	customerRecords: state.Customer.getIn(['customerRecords'], []),
	coruscantAIDUser: state.Customer.getIn(['coruscantAIDUser'], null),
});

const mapDispatchToProps = () => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(Results);