import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import NotLoggedIn from '../../../components/layout/NotLoggedIn';
import SearchComponent from '../../../components/pages/customers/CustomerSearch';
import AIDSearch from '../../../components/pages/customers/AIDSearch';
import Results from './Results/index';
import { searchContainer } from "../../../styles/components/pages/home/home";
import { pageContainer } from "../../../styles/components/layout/main";


class CustomerContainer extends React.Component {
	render() {
		const { user, loading } = this.props;
		return (
			<div css={pageContainer}>
				{loading && <div className="loader"/>}
				{
					!user ?
						<>
							<p className="page-title">Customer support portal</p>
							<NotLoggedIn/>
						</>
						: <>
							<div css={searchContainer}>
								<SearchComponent />
								<AIDSearch />
							</div>
						
							<Results />
						</>
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: state.PersonalData.getIn(['user'], null),
	loading: state.Customer.getIn(['loading'], false),
});

const mapDispatchToProps = () => ({ });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerContainer));