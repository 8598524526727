import React from "react";
import { table } from "../../../../styles/components/pages/home/home";
import { subtitle } from "../../../../styles/components/layout/main";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router";

const HistoryCustomerNumber = (props) => (
	<>
		<div css={subtitle}>History of Customer Number changes for user id  {props.coruscantUserId} </div>
			<Table css={table} bordered hover>
				<thead>
				<tr>
					<th>Customer Number New</th>
					<th>Customer Number Old</th>
					<th>Created</th>
					<th>By whom email</th>
					<th>By whom name</th>
				</tr>
				</thead>
				<tbody>
				{props.historyCNumberRecords.map((record) => {
					const dateTime = new Date(record.createdAt).toString();
					return (
						<tr key={record.eventId}>
							<td> {record.customerNumberNew} </td>
							<td> {record.customerNumberOld} </td>
							<td> {dateTime} </td>
							<td> {record.email} </td>
							<td> {record.name} </td>
						</tr>
					)
				})
				}
				</tbody>
			</Table>
	</>
)

export default withRouter(HistoryCustomerNumber);