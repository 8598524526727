import React from 'react';
import { Link } from "react-router-dom";
import Routes from '../../constants/RoutesConstants';
import AIDStatus from '../common/AIDStatus';

export default () => (
	<div id="header">
		<Link className="brand" to={Routes.main}/>
		<Link className="menu-link" to={Routes.users}>
			<span>Users List</span>
		</Link>
		<AIDStatus />
	</div>
);