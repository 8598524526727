import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-responsive-modal';
import ModalNames from '../../constants/Modals';
import { ToggleModal } from '../../actions/UIActions';
import ButtonComponent from '../../components/common/Button';


class UserModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.closeModal = this.closeModal.bind(this);
		this.changeValue = this.changeValue.bind(this);
		this.saveUser = this.saveUser.bind(this);
	}
	
	componentWillReceiveProps(nextProps) {
		if (!nextProps.open) {
			this.setState({
				email: '',
				name: '',
				picture: '',
				isAccepted: false,
			});
		} else {
			this.setState({...nextProps.user})
		}
	}
	
	saveUser(event) {
		event.preventDefault();
		const {onSuccess} = this.props;
		ToggleModal(ModalNames.USER_MODAL, false);
		if (onSuccess) {
			onSuccess(this.state);
		}
	}
	
	closeModal() {
		const {onAbort} = this.props;
		ToggleModal(ModalNames.USER_MODAL, false);
		if (onAbort) {
			onAbort();
		}
	}
	
	changeValue(event) {
		const {value, name} = event.target;
		this.setState({[name]: value});
	}
	
	render() {
		const {open} = this.props;
		const {email = '', name = ''} = this.state;
		const {user: {isNew = true}} = this.props;
		
		return (
			<Modal
				open={open}
				onClose={this.closeModal}
				center
			>
				<form id="user-modal" onSubmit={this.saveUser}>
					<label>
						<span className="label-text">Name:</span>
						<input
							className="user-info"
							type="text"
							name="name"
							value={name}
							onChange={this.changeValue}
						/>
					</label>
					
					<label>
						<span className="label-text">Email:</span>
						<input
							className="user-info"
							type="email"
							name="email"
							value={email}
							onChange={this.changeValue}
							required
							disabled={!isNew}
						/>
					</label>
					<ButtonComponent
						className="success close"
						type="submit"
						title={isNew ? 'Create' : 'Save'}
					/>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = state => ({
	open: state.UI.getIn([ModalNames.USER_MODAL], false),
	onAbort: state.UI.getIn(['modalData', 'onAbort'], () => {}),
	onSuccess: state.UI.getIn(['modalData', 'onSuccess'], () => {}),
	user: state.UI.getIn(['modalData', 'user'], {})
});

export default connect(mapStateToProps, () => ({}))(UserModal);