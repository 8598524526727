module.exports = {
	base: "16px",
	spacing: "1rem",
	breakpoints: {
		xs: "0em" /* 0px */,
		sm: "30em" /* 480px */,
		md: "64em" /* 1024px */,
		lg: "75em" /* 1200px */
	},
	typography: {
		font: "'Open Sans', sans-serif",
		text: "1rem",
		title: "2rem"
	},
	constants: {
		loader_height: 6,
		loader_width: 120,
		auto_close: 3000,
		header_height: '50px'
	},
	colors: {
		white: '#ffffff',
		black: '#000000',
		blueLight: '#3498db',
		blueLight2: '#3885ff',
		greenSalad: '#4BB543',
		greenSaladFocus: '#468c41',
		grey: '#676767',
		// test: '#dcdcdc',
		greyLight: '#e2e2e2',
		greyLight2: '#e8e8e8',
		greyVeryLight: '#f4f4f4',
		greySpanish: '#979797',
		grey23: '#3b3b3b',
		grey64: '#a3a3a3',
		greyMediumDark: '#4b4b4b',
		greyDark: '#636363',
		greyDark2: '#2a2a2a',
		greyDark3: '#8a8a8a',
		redLight: '#ff2323',
		redVenetian: '#d0021b',
		redRossoCorsa: '#D60000',
		redHover: '#d62608',
		redFocus: '#95141a',
		green: "#8ABA56",
		greenLight: "#b0d08c",
		greenLighter: "#d5e6c3",
		transparent: 'transparent',
		warning: "#E69939",
		warningFocus: "#895823",
		warningHover: "#896b1d",
		action: "#3498db",
		actionFocus: "#003b99",
		actionHover: "#0058e6",
	},
};