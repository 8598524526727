import React from 'react';
import {
	Container,
	Row,
	Form,
	Col,
	Button
} from 'react-bootstrap'
import { numberValidator } from '../../../utils/validation';
import { updateCustomer } from '../../../actions/CustomerActions';
import toast from '../../../utils/toast';
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { pageContainer, formContainer } from "../../../styles/components/layout/main";
import { buttonSuccess, buttonPrimary, BackButton, buttonAction } from "../../../styles/shared/buttons";
import { editPageContainer, buttonContainer } from "../../../styles/components/pages/edit/editCustomer";


class EditPage extends React.Component {
	constructor(props) {
		super(props);
		const { customer_number, id } = this.props.current;
		
		this.state = {
			id: id,
			customerNumber: customer_number,
			customerNumberInit: customer_number,
			showCustomerEditForm: false,
		};
	}
	
	updateCustomerNumber = ({ currentTarget: { value } }) => {
		this.setState({ customerNumber: value });
		
		if (numberValidator.validate(value)) {
			this.setState({
				customerNumber: parseInt(value),
				id: this.state.id,
			});
		}
	};
	
	submit = async () => {
		const {id, customerNumber, customerNumberInit} = this.state;
		if (id && customerNumber) {
			try {
				await updateCustomer({ id, customer_number: customerNumber, customer_number_old: customerNumberInit });
				toast.success("Customer number was updated")
			} catch (err) {
				this.setState({ customerNumber: customerNumberInit });
				toast.error(err.message);
			}
		}
	};

	enableEditForm = () => {
		this.setState({showCustomerEditForm: true})
	}

	customerNumberPlain = (cNumber) => {
		return (
			<Form.Group as={Row}>
				<Form.Label column sm="3"><b>Customer Number</b></Form.Label>
				<Col sm="5">
					<Form.Control plaintext readOnly defaultValue={cNumber} />
				</Col>
				<Col sm="4" css={buttonContainer}>
					<Button css={buttonAction} onClick={() => this.enableEditForm()}>Edit</Button>
				</Col>
			</Form.Group>
		)
	}

	customerNumberEdit = (cNumber) => {
		return (
			<Form.Group as={Row}>
				<Form.Label column sm="3"><b>Customer Number</b></Form.Label>
				<Col sm="5">
					<Form.Control
						placeholder="Customer number"
						onChange={this.updateCustomerNumber}
						value={cNumber}
					/>
				</Col>
				
				<Col sm="4" css={buttonContainer}>
					<Button css={buttonSuccess} onClick={() => this.submit()}>Save</Button>
					<Button css={buttonPrimary} onClick={() => this.cancel()}>Cancel</Button>
				</Col>
			</Form.Group>
		)
	}
	
	onKeyUp = e => e.keyCode === 13 && this.submit();
	
	cancel = e => this.setState({ 
		customerNumber: this.state.customerNumberInit,
		showCustomerEditForm: false,
	 })

	render() {
		const { loading } = this.props;
		const { customerNumber, showCustomerEditForm } = this.state;
		const {
			id,
			email,
			aid_uuid,
			phone_number,
			is_converted,
			connectid_uid,
		} = this.props.current;
		
		return (
			<Container css={pageContainer}>
				{loading && <div className="loader"/>}
				<BackButton className="fas fa-arrow-left" to={"/"}/>
				
				<div css={editPageContainer}>
					<Form css={formContainer}>
						{showCustomerEditForm && this.customerNumberEdit(customerNumber)}
						{!showCustomerEditForm && this.customerNumberPlain(customerNumber)}
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>Email</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={email} />
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>Phone Number</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={phone_number} />
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>User Id</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={id} />
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>User ConnectID</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={connectid_uid} />
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>Converted</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={is_converted ? 'Yes' : 'No'} />
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm="3"><b>AID UUID</b></Form.Label>
							<Col sm="9">
								<Form.Control plaintext readOnly defaultValue={aid_uuid} />
							</Col>
						</Form.Group>
					</Form>
				</div>
			</Container>
		)
	}
}

const mapStateToProps = (state, props) => ({
	current: state.Customer.getIn(['customerRecords'], {}).find(item => item.id === props.match.params.id),
	loading: state.Customer.getIn(['loading'], false),
});

const mapDispatchToProps = () => ({ });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPage));