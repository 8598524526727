import {combineReducers} from 'redux';
import UIReducer from './UIReducer';
import PersonalDataReducer from './PersonalDataReducer';
import UsersReducer from './UsersReducer';
import CustomerReducer from './CustomersReducer';
import HistoryReducer from "./HistoryReducer";

const MainReducer = combineReducers({
	UI: UIReducer,
	PersonalData: PersonalDataReducer,
	Users: UsersReducer,
	Customer: CustomerReducer,
	History: HistoryReducer
});
export default MainReducer;