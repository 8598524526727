import React from "react";
import { table } from "../../../../styles/components/pages/home/home";
import { Table } from "react-bootstrap";
import { subtitle, centerText } from "../../../../styles/components/layout/main";
import {Link} from "react-router-dom";


export default ({ aidUser }) => (
	<>
		<div css={subtitle}>Coruscant Aid Users</div>
		<Table css={table} bordered hover>
			<thead>
			<tr>
				<th>AID UUID</th>
				<th>User ID</th>
				<th>Email</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			{
				<tr>
					<td>{aidUser.aid_uuid}</td>
					<td>{aidUser.user_id}</td>
					<td>{aidUser.email}</td>
					<td css={centerText}>
						<Link to={`/view`}>View</Link>
					</td>
				</tr>
			}
			</tbody>
		</Table>
	</>
);