import React from 'react';
import {Route, Switch} from 'react-router';
import {withRouter} from "react-router";
import {connect} from 'react-redux';
import {getUser} from '../actions/UsersActions';
import LoaderComponent from './common/Loader';
import Home from './pages/customers/CustomerContainer';
import ClosedRoute from './common/ClosedRoute';
import UsersPage from './pages/users/List';
import EditPage from './pages/editCustomer/EditPage';
import HistoryPage from './pages/customers/History/HistoryPage';
import ViewAidUserPage from './pages/viewAidUser/ViewAidUserPage';


class Router extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			promise: getUser(),
		}
	}
	
	render() {
		const {promise} = this.state;
		const {user, customerRecords, coruscantAIDUser} = this.props;
		
		return (
			<LoaderComponent promise={promise}>
				<Switch>
					<Route exact path="/" component={Home}/>
					<ClosedRoute path="/users" condition={!!user} component={UsersPage}/>
					<ClosedRoute path="/edit/:id" condition={!!user && customerRecords.length} component={EditPage}/>
					<ClosedRoute path="/history/:id" condition={!!user && customerRecords.length} component={HistoryPage}/>
					<ClosedRoute path="/view" condition={!!user && coruscantAIDUser} component={ViewAidUserPage}/>
				</Switch>
			</LoaderComponent>
		)
	}
}

const mapStateToProps = state => ({
	user: state.PersonalData.getIn(['user'], null),
	customerRecords: state.Customer.getIn(['customerRecords'], []),
	coruscantAIDUser: state.Customer.getIn(['coruscantAIDUser'], null),
});

const mapDispatchToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));