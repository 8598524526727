import {toast} from 'react-toastify';

export default {
	success(msg, options) {
		return toast.success(msg, {
			...options,
			className: "toast-success",
			progressClassName: "toast-success-bg"
		});
	},
	error(msg, options) {
		return toast.error(msg, {
			...options,
			className: "toast-error",
			progressClassName: "toast-error-bg"
		});
	}
}
