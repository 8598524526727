import { createStore, applyMiddleware, compose } from 'redux';
import MainReducer from '../reducers/Main';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import hookMiddleware from 'redux-hook-middleware';
import { routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise-middleware';
import createBrowserHistory from 'history/createBrowserHistory';

const history = createBrowserHistory();
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

const logger = createLogger({
    collapsed: true,
});

const middleware = [
    applyMiddleware(promiseMiddleware()),
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(logger, thunk, hookMiddleware),
];

const Store = createStore(MainReducer, composeEnhancers(...middleware));

export default Store;
