import React from 'react';
import Header from '../../components/layout/Header';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/scss/main.scss';
import UserModal from '../../components/modals/UserModal';

export default ({ children }) => (
	<>
		<Header />
		<>{children}</>
		<ToastContainer toastClassName="toast" autoClose={3000}/>
		<UserModal/>
	</>
);