export function debounce(callback, timer = 0) {
    let timeout;

    return function exec() {
        let ctx = this;
        let args = arguments;

        let delayed = () => {
            timeout = null;
            callback.apply(ctx, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(delayed, timer);
    }
}