import './index.html';
import './styles/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Router from 'components/Router';
import Store from 'stores/Main';
import Layout from 'components/layout/Main';
import {initDeviceWatcher} from 'actions/UIActions';

class App extends React.Component {
	componentWillMount() {
		initDeviceWatcher();
	}
	
	render() {
		return (
			<Provider store={Store}>
				<BrowserRouter>
					<Layout>
						<Router/>
					</Layout>
				</BrowserRouter>
			</Provider>
		);
	}
}

const rootContainer = document.getElementById('app');
ReactDOM.render(
	<App/>,
	rootContainer
);