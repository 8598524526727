import {handleActions} from 'redux-actions';
import mergers from 'seamless-immutable-mergers';
import Immutable from 'seamless-immutable';
import CustomerConstants from '../constants/CustomerConstants';

const mergeConfig = {
	merger: mergers.updatingByIdArrayMerger,
	mergerObjectIdentifier: 'id',
	deep: true,
};

export const InitialState = Immutable.from({
	customerRecords: [],
	coruscantAIDUser: null,
	loading: false,
});

const CustomerReducer = handleActions({
	[CustomerConstants.IS_LOADING]: state => state.set('loading', true),
	[CustomerConstants.IS_ERROR]: state => state.set('loading', false),
	
	[CustomerConstants.GET_CUSTOMER_RECORDS]: (state, action) =>
		state
			.set('customerRecords', action.payload)
			.set('loading', false),
	
	[CustomerConstants.UPDATE_CUSTOMER]: (state, action) => {
		const payload = {
			id: action.payload.id,
			customer_number: action.payload.customer_number,
		};
		return state.merge({customerRecords: [payload], loading: false}, mergeConfig)
	},
	
	[CustomerConstants.GET_CORUSCANT_AID_USER]: (state, action) =>
		state
		.set('coruscantAIDUser', action.payload)
		.set('loading', false),
	
}, InitialState);


export default CustomerReducer;