import {createAction} from 'redux-actions';
import UIConstants from '../constants/UIConstants';
import Store from '../stores/Main';
import {debounce} from '../services/Utils';
import {API_URL} from '../services/API';
import { APIGet, APIFetchErrorText } from "../services/API";


const getDeviceAction = createAction(UIConstants.DEVICE_CHANGED);
const openModal = createAction(UIConstants.OPEN_MODAL);
const closeModal = createAction(UIConstants.CLOSE_MODAL);
const updateAIDEmergency = createAction(UIConstants.UPDATE_AIDEMERGENCY);

export function LogIn() {
	window.location.replace(`${API_URL}/auth`);
}

export function LogOut() {
	window.location.replace(`${API_URL}/logout`);
}

export function CheckDevice() {
	Store.dispatch(getDeviceAction(document.documentElement.clientWidth < UIConstants.MAX_MOBILE_WIDTH));
}

export function ToggleModal(name, state, data) {
	Store.dispatch(state ? openModal({name, data}) : closeModal(name));
}

export function initDeviceWatcher() {
	CheckDevice();
	window.addEventListener('resize', debounce(CheckDevice, 100));
}

export const getAIDemergency = async () => {
	try {
		const { data: eStatus } = await APIGet('/aid/status');
		Store.dispatch(updateAIDEmergency(eStatus));
	} catch (err) {
		Store.dispatch(updateAIDEmergency({emergency: true})); // let alert folks that something is wrong
		err.message = APIFetchErrorText(err)
		throw err;
	}
};
