import React from 'react';
import ButtonComponent from '../../components/common/Button';
import { LogIn } from '../../actions/UIActions';

export default () => (
	<div id="not-logged-in">
		<p className="login-message">
			Hei. Du er ikke logget inn.
			<br/>
			Gjør det først!
		</p>
		<ButtonComponent
			className="login-btn"
			onClick={LogIn}>
			Log in button
		</ButtonComponent>
	</div>
);