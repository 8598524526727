import {handleActions} from 'redux-actions';
import Immutable from 'seamless-immutable';
import HistoryConstants from '../constants/HistoryConstants';

export const InitialState = Immutable.from({
	historyCNumberRecords: [],
	loading: false,
});

const HistoryReducer = handleActions({
	[HistoryConstants.IS_LOADING_HISTORY]: state => state
		.set('loading', true)
		.set('historyCNumberRecords', {historyCNumberRecords: []})
	,
	[HistoryConstants.IS_ERROR_HISTORY]: state => state
		.set('loading', false)
		.set('historyCNumberRecords', {historyCNumberRecords: []})
	,
	[HistoryConstants.GET_HISTORY_CNUMBER]: (state, action) =>
		state
			.set('historyCNumberRecords', action.payload)
			.set('loading', false),
}, InitialState);


export default HistoryReducer;