import {handleActions} from 'redux-actions';
import Immutable from 'seamless-immutable';
import PersonalDataConstants from '../constants/PersonalDataConstants';

export const InitialState = Immutable.from({
	user: null
});

const PersonalDataReducer = handleActions({
	[PersonalDataConstants.GET_USER]: (state, action) => {
		return state.set('user', action.payload)
	},
}, InitialState);

export default PersonalDataReducer;