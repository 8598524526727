import { css } from '@emotion/core';
import theme from 'Theme';

export const box = css`
	padding: 1rem 2rem;
`;

export const editPageContainer = css`
	${box};
	background-color: ${theme.colors.white};
`;

export const buttonContainer = css`
	justify-content: space-between;
	align-items: center;
	display: flex;
	
	button {
		width: 48%;
	}
`;